<script lang="ts" setup>
import { computed } from 'vue';
import { GIcon } from '@gem/icons';
import { BUTTON_STYLE_BY_SIZE, BUTTON_STYLE_BY_TYPE } from '../const/button';
import { cn } from '@gem/common';
import type { ButtonProps } from '../types/button';

const emit = defineEmits(['click']);
const props = withDefaults(defineProps<ButtonProps>(), {
  buttonType: 'button',
  type: 'primary',
  size: 'large',
  justifyContent: 'center',
});

const disableHover = computed(() => props?.noSelect || props.loading);

const buttonStylesByTypeAndState = computed((): string => {
  const buttonType = props.type
    ? BUTTON_STYLE_BY_TYPE[props.type] || BUTTON_STYLE_BY_TYPE.primary
    : BUTTON_STYLE_BY_TYPE.primary;
  const mode = props.lightMode ? 'light' : 'dark';
  if (props.active) return buttonType.active[mode];
  if (props.disable) return buttonType.disabled[mode];
  return cn(buttonType.default[mode], disableHover.value ? '' : buttonType.hover[mode]);
});

const buttonStylesBySize = computed((): string =>
  props.size ? BUTTON_STYLE_BY_SIZE[props.size] : BUTTON_STYLE_BY_SIZE.large,
);

const buttonStylesForOnlyIcon = computed((): string => (props.onlyIcon ? 'aspect-square !p-0' : ''));

const buttonWidthStyle = computed(() => {
  if (props.buttonWidth) {
    return props.buttonWidth === 'full' ? '100%' : props.buttonWidth;
  }
  return '';
});

const buttonRoundedStyle = computed(() => {
  const roundedClasses = ['rounded-xl'];
  if (props.noRounded) {
    if (props.noRounded === 'left') roundedClasses.push('rounded-l-none');
    if (props.noRounded === 'right') roundedClasses.push('rounded-r-none');
    if (props.noRounded === 'top') roundedClasses.push('rounded-t-none');
    if (props.noRounded === 'bottom') roundedClasses.push('rounded-b-none');
  }
  return cn(roundedClasses);
});

const iconSizeByButtonSize = computed(() => {
  if (props.iconSize) return props.iconSize;
  if (props.size == 'large') return 20;
  if (props.size == 'medium') return 20;
  if (props.size == 'semi-medium') return 16;
  if (props.size == 'normal') return 16;
  if (props.size == 'small') return 14;
  return 16;
});

const handleClick = (e: Event) => {
  if (props.loading) {
    return;
  }
  emit('click', e);
};
</script>
<template>
  <button
    :id="id"
    :data-type="type"
    :data-mode="lightMode ? 'light' : 'dark'"
    :type="buttonType"
    class="relative flex cursor-pointer items-center justify-center gap-8 overflow-hidden whitespace-nowrap border font-medium outline-none transition-colors duration-200 disabled:cursor-not-allowed"
    :class="
      cn([
        buttonStylesByTypeAndState,
        buttonStylesBySize,
        buttonStylesForOnlyIcon,
        buttonRoundedStyle,
        loading ? '!cursor-not-allowed' : '',
        noSelect ? 'select-none' : '',
      ])
    "
    :style="{
      width: buttonWidthStyle,
      paddingLeft: paddingX ? `${paddingX}px` : '',
      paddingRight: paddingX ? `${paddingX}px` : '',
      justifyContent: justifyContent,
    }"
    data-test="editor-control-GButtonV2"
    :disabled="disable && !hideAttributeDisabled"
    @click="handleClick">
    <!-- Loading icon -->
    <div v-if="loading" class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
      <GIcon :size="16" :name="iconLoading || 'loading'" :spin-infinity="true" />
    </div>

    <!-- Icon Only -->
    <GIcon v-if="onlyIcon" :class="cn([loading ? 'invisible' : ''])" :name="onlyIcon" :size="iconSizeByButtonSize" />

    <!-- Button Content -->
    <template v-if="!onlyIcon">
      <div class="flex items-center justify-center gap-8" :class="cn([loading ? 'invisible' : ''])">
        <GIcon v-if="iconBefore" :name="iconBefore" :size="iconSizeByButtonSize" />
        <slot></slot>
        <GIcon v-if="iconAfter" :name="iconAfter" :size="iconSizeByButtonSize" />
      </div>
    </template>
  </button>
</template>
