<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import type { GInputProps } from '../types/input';
import type { ButtonSize } from '@gem/uikit-v2';
import { GButton } from '@gem/uikit-v2';
import { BUTTON_STYLE_BY_SIZE, INPUT_STYLE_BY_TYPE } from '../const/input';
import { cn } from '@gem/common';
import { GIcon } from '@gem/icons';

const props = withDefaults(defineProps<GInputProps>(), {
  inputStyle: 'normal',
  type: 'text',
  size: 'medium',
  disable: false,
  errorMessage: '',
});

const inputRef = ref<HTMLInputElement>();

function focus() {
  if (inputRef.value) {
    inputRef.value.focus();
  }
}
defineExpose({
  focus,
});

const stylesByTypeAndState = computed((): string => {
  const style = props.inputStyle
    ? INPUT_STYLE_BY_TYPE[props.inputStyle] || INPUT_STYLE_BY_TYPE.normal
    : INPUT_STYLE_BY_TYPE.normal;
  const mode = props.lightMode ? 'light' : 'dark';
  if (props.readonly) return style.default[mode];
  if (props.disable) return style.disabled[mode];
  if (props.errorMessage) return style.error[mode];
  return cn(style.default[mode], style.hover[mode], style.active[mode]);
});

const styleBorder = computed(() => {
  const classNames = ['rounded-xl'];
  if (props.noBorder) {
    if (props.noBorder === 'left') classNames.push('rounded-l-none border-l-0');
    if (props.noBorder === 'right') classNames.push('rounded-r-none border-r-0');
    if (props.noBorder === 'top') classNames.push('rounded-t-none border-t-0');
    if (props.noBorder === 'bottom') classNames.push('rounded-b-none border-b-0');
  }
  return cn(classNames);
});

const errorMessageColor = computed((): string => {
  if (props.errorMessage) {
    return props.lightMode ? 'text-red-400' : 'text-red-200';
  }
  return '';
});

const stylesBySize = computed((): string =>
  props.size ? BUTTON_STYLE_BY_SIZE[props.size] : BUTTON_STYLE_BY_SIZE.large,
);

//=================== Emit Function =====================
const emit = defineEmits<{
  (e: 'change', value: string): void;
  (e: 'onChange', value: string): void;
  (e: 'handleBlur', value: string): void;
  (e: 'focus'): void;
}>();
//=================== End Emit Function =====================

//=================== Methods ====================
const emitValue = (type: 'change' | 'onChange' | 'blur', $event: any) => {
  const newValue = $event?.target?.value?.trim();
  parseValue.value = newValue;
  const valueEmit = removeEnter(newValue);
  if (type === 'change') emit('change', valueEmit);
  else if (type === 'blur') emit('handleBlur', newValue);
  else emit('onChange', newValue);
};

const removeEnter = (value?: string) => {
  return value?.toString()?.replace(/&nbsp;/g, ' ') || '';
};

const handleClear = () => {
  parseValue.value = '';
  emit('change', '');
};
//=================== End Methods =====================

const parseValue = ref(removeEnter(props.value));

const getStringLength = (str: string) => {
  return new TextEncoder().encode(str).length;
};

const maxLengthLabel = computed(() => {
  if (!props.maxLength) return '';
  const characterCount = parseValue.value?.length ? getStringLength(parseValue.value) : 0;
  return `${characterCount}/${props.maxLength}`;
});

const prefixIconSize = computed((): ButtonSize => {
  switch (props.size) {
    case 'large':
      return 'semi-medium';
    case 'medium':
      return 'normal';
    case 'small':
      return 'extra-small';
    default:
      return 'normal';
  }
});

const inputPaddingBySize = computed(() => {
  switch (props.size) {
    case 'large':
      return 'pl-42';
    case 'medium':
      return 'pl-40';
    case 'small':
      return 'pl-32';
    default:
      return 'pl-40';
  }
});

onMounted(() => {
  if (props.active && !props.readonly) focus();
});

watch(
  () => props.value,
  () => {
    parseValue.value = props.value;
  },
);
</script>

<template>
  <div class="relative w-full">
    <div class="relative flex w-full items-center">
      <template v-if="label"> </template>
      <div v-if="readonly" class="absolute left-0 top-0 h-full w-full cursor-pointer" />
      <div v-if="prefixIcon" class="absolute left-4 top-1/2 -translate-y-1/2">
        <GButton
          :type="active ? 'primary' : 'secondary'"
          :size="prefixIconSize"
          :only-icon="prefixIcon"
          :no-select="true" />
      </div>
      <input
        ref="inputRef"
        class="w-full text-ellipsis rounded-xl outline-none transition-colors duration-200"
        data-test="editor-control-g-input-text"
        :autocomplete="false"
        :placeholder="placeholder"
        :class="
          cn([
            stylesByTypeAndState,
            stylesBySize,
            styleBorder,
            maxLength ? (maxLength >= 100 ? 'pr-[58px]' : 'pr-[48px]') : '',
            prefixIcon ? inputPaddingBySize : '',
            clearButton && !disable ? 'pr-[40px]' : '',
            readonly ? 'cursor-pointer select-none' : '',
          ])
        "
        :style="{
          paddingLeft: paddingLeft,
          paddingRight: paddingRight,
        }"
        :type="type"
        :value="parseValue"
        v-bind="$attrs"
        :disabled="disable"
        :maxlength="maxLength"
        @focus="() => emit('focus')"
        @change="(e: any) => emitValue('change', e)"
        @input="(e: any) => emitValue('onChange', e)"
        @blur="(e: any) => emitValue('blur', e)" />
      <div v-if="maxLength && !clearButton" class="text-text-light-100 text-12 leading-20 absolute right-8">
        {{ maxLengthLabel }}
      </div>
      <template v-if="clearButton && !disable">
        <div v-if="parseValue" class="absolute right-8">
          <GButton type="ghost" size="small" only-icon="polaris-x" :light-mode="lightMode" @click.stop="handleClear" />
        </div>
      </template>
    </div>
    <div
      v-if="errorMessage !== ''"
      class="text-12 font-regular mt-8 flex flex-row gap-8"
      :class="cn([errorMessageColor])">
      <GIcon name="polaris-alert-circle" :size="16" /><span>{{ errorMessage }}</span>
    </div>
  </div>
</template>
