<script lang="ts" setup>
type GTapProps = {
  tabs: {
    id: string;
    name: string;
  }[];
  activeTabId: string;
  itemWidth: string;
};

defineProps<GTapProps>();

const emit = defineEmits<(e: 'onChangeTabActive', tabID: string) => void>();

const handleActiveTab = (tabID: string) => {
  emit('onChangeTabActive', tabID);
};
</script>

<template>
  <div class="flex select-none items-center gap-16">
    <div
      v-for="tab in tabs"
      :key="tab.id"
      data-test="editor-setting-tab"
      class="flex h-40 w-1/2 cursor-pointer items-center justify-center px-12 py-10 transition-colors duration-200"
      :class="{
        'text-text-dark-300 hover:text-light-100 border-b-[1px]  border-solid border-transparent':
          tab.id !== activeTabId,
        'border-primary-300 text-light-100 bg-primary-300/15 rounded-t-xl border-b-[2px] border-solid':
          tab.id === activeTabId,
      }"
      :style="{
        width: itemWidth,
      }"
      @click="handleActiveTab(tab.id)">
      <p class="text-14 font-medium">
        {{ tab.name }}
      </p>
    </div>
  </div>
</template>
